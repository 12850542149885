import * as React from 'react';
import {
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    Heading,
    Input,
    VStack,
    Text,
    useColorModeValue,
    Select,
    Divider,
    HStack,
    useToast,
    FormErrorMessage,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaGoogle, FaFacebook } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';

interface SignUpFormData {
    name: string;
    email: string;
    password: string;
    dateOfBirth: string;
    gender: 'male' | 'female' | 'other';
}

const SignUp: React.FC = () => {
    const navigate = useNavigate();
    const { register, socialLogin, loading, error: authError } = useAuth();
    const toast = useToast();
    const [formData, setFormData] = React.useState<SignUpFormData>({
        name: '',
        email: '',
        password: '',
        dateOfBirth: '',
        gender: 'other'
    });
    const [error, setError] = React.useState<Record<string, string>>({});

    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.600', 'gray.400');
    const buttonBg = useColorModeValue('green.500', 'green.400');
    const buttonHoverBg = useColorModeValue('green.600', 'green.500');

    const validateForm = () => {
        const newErrors: Record<string, string> = {};
        
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        }
        
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Invalid email address';
        }
        
        if (!formData.password) {
            newErrors.password = 'Password is required';
        } else if (formData.password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters';
        }
        
        if (!formData.dateOfBirth) {
            newErrors.dateOfBirth = 'Date of birth is required';
        }
        
        setError(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        // Clear error when user starts typing
        if (error[name]) {
            setError(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        if (!validateForm()) {
            toast({
                title: 'Validation Error',
                description: 'Please check the form for errors',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            await register(formData.name, formData.email, formData.password);
            toast({
                title: 'Account created',
                description: 'Welcome to our platform!',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (err) {
            toast({
                title: 'Error',
                description: authError || 'Failed to create account',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSocialLogin = async (provider: 'google' | 'facebook') => {
        try {
            await socialLogin(provider);
        } catch (err) {
            toast({
                title: 'Error',
                description: authError || `Failed to login with ${provider}`,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Container maxW="container.sm" py={10}>
            <VStack spacing={8}>
                <VStack spacing={2} textAlign="center">
                    <Heading 
                        fontSize={{ base: '2xl', md: '3xl' }}
                        color={useColorModeValue('green.600', 'green.300')}
                    >
                        Create Your Account
                    </Heading>
                    <Text color={textColor}>
                        Join our community and start your journey to finding love
                    </Text>
                </VStack>
                <Box
                    w="full"
                    bg={bgColor}
                    boxShadow="lg"
                    rounded="lg"
                    p={8}
                >
                    <form onSubmit={handleSubmit}>
                        <VStack spacing={6}>
                            <VStack w="full" spacing={4}>
                                <Button
                                    w="full"
                                    colorScheme="red"
                                    leftIcon={<FaGoogle />}
                                    onClick={() => handleSocialLogin('google')}
                                    isLoading={loading}
                                >
                                    Continue with Google
                                </Button>
                                <Button
                                    w="full"
                                    colorScheme="facebook"
                                    leftIcon={<FaFacebook />}
                                    onClick={() => handleSocialLogin('facebook')}
                                    isLoading={loading}
                                >
                                    Continue with Facebook
                                </Button>
                            </VStack>

                            <Divider />

                            <FormControl isInvalid={!!error.name}>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Enter your name"
                                />
                                <FormErrorMessage>{error.name}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!!error.email}>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Enter your email"
                                />
                                <FormErrorMessage>{error.email}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!!error.password}>
                                <FormLabel>Password</FormLabel>
                                <Input
                                    name="password"
                                    type="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    placeholder="Create a password"
                                />
                                <FormErrorMessage>{error.password}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!!error.dateOfBirth}>
                                <FormLabel>Date of Birth</FormLabel>
                                <Input
                                    name="dateOfBirth"
                                    type="date"
                                    value={formData.dateOfBirth}
                                    onChange={handleChange}
                                />
                                <FormErrorMessage>{error.dateOfBirth}</FormErrorMessage>
                            </FormControl>

                            <FormControl>
                                <FormLabel>Gender</FormLabel>
                                <Select
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                >
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </Select>
                            </FormControl>

                            <Button
                                type="submit"
                                w="full"
                                bg={buttonBg}
                                color="white"
                                _hover={{ bg: buttonHoverBg }}
                                isLoading={loading}
                            >
                                Create Account
                            </Button>

                            <Text color={textColor} fontSize="sm">
                                Already have an account?{' '}
                                <Button
                                    variant="link"
                                    color={useColorModeValue('green.600', 'green.300')}
                                    onClick={() => navigate('/login')}
                                >
                                    Sign in
                                </Button>
                            </Text>
                        </VStack>
                    </form>
                </Box>
            </VStack>
        </Container>
    );
};

export default SignUp;