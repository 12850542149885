import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

const Discover: React.FC = () => {
    const { t } = useLanguage();

    return (
        <div>
            <h1>{t('discover')}</h1>
        </div>
    );
};

export default Discover;
