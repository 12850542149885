/// <reference lib="dom" />
import axios, { AxiosError, AxiosResponse } from 'axios';
import { authService } from './authService';

export class ApiError extends Error {
    constructor(
        message: string,
        public status?: number,
        public code?: string,
        public data?: any
    ) {
        super(message);
        this.name = 'ApiError';
    }
}

console.log('Creating API instance with baseURL:', 'http://localhost:3001');

const api = axios.create({
    baseURL: 'http://localhost:3001',
    headers: {
        'Content-Type': 'application/json',
    },
});

// Request interceptor
api.interceptors.request.use(
    (config) => {
        const token = authService.getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        console.error('Request interceptor error:', error);
        return Promise.reject(error);
    }
);

// Response interceptor
api.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    async (error: AxiosError) => {
        const originalRequest = error.config;

        // Handle network errors
        if (!error.response) {
            console.error('Network error:', error);
            throw new ApiError('Network error - please check your connection');
        }

        const { status, data } = error.response;

        // Handle 401 Unauthorized errors
        if (status === 401) {
            if (originalRequest?.url !== '/auth/refresh') {
                try {
                    // Attempt to refresh the token
                    const newToken = await authService.refreshToken();
                    
                    // Retry the original request with the new token
                    if (originalRequest) {
                        originalRequest.headers.Authorization = `Bearer ${newToken}`;
                        return api(originalRequest);
                    }
                } catch (refreshError) {
                    console.error('Token refresh failed:', refreshError);
                    authService.clearToken();
                    window.location.href = '/login';
                    throw new ApiError('Session expired - please login again');
                }
            } else {
                authService.clearToken();
                window.location.href = '/login';
                throw new ApiError('Session expired - please login again');
            }
        }

        // Handle other errors
        const errorMessage = data?.message || 'An unexpected error occurred';
        console.error('API Error:', {
            status,
            message: errorMessage,
            data: data
        });

        throw new ApiError(
            errorMessage,
            status,
            data?.code,
            data
        );
    }
);

// Test the API connection
api.get('/health-check')
    .then(() => console.log('API connection successful'))
    .catch((error) => console.error('API connection test failed:', error.message));

export default api;
