import React, { useState } from 'react';
import {
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Text,
    useToast,
    Link,
    Heading,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useLanguage } from '../contexts/LanguageContext';

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { login } = useAuth();
    const { t } = useLanguage();
    const toast = useToast();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            await login(email, password);
            toast({
                title: t('loginSuccess'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: t('loginError'),
                description: error instanceof Error ? error.message : t('unexpectedError'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container maxW="container.sm" py={10}>
            <VStack spacing={8} align="stretch">
                <Box textAlign="center">
                    <Heading as="h1" size="xl" mb={2}>
                        {t('welcomeBack')}
                    </Heading>
                    <Text color="gray.600">
                        {t('loginToContinue')}
                    </Text>
                </Box>

                <Box as="form" onSubmit={handleSubmit}>
                    <VStack spacing={4}>
                        <FormControl isRequired>
                            <FormLabel>{t('email')}</FormLabel>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={t('enterEmail')}
                            />
                        </FormControl>

                        <FormControl isRequired>
                            <FormLabel>{t('password')}</FormLabel>
                            <Input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder={t('enterPassword')}
                            />
                        </FormControl>

                        <Button
                            type="submit"
                            colorScheme="blue"
                            width="full"
                            isLoading={isLoading}
                        >
                            {t('login')}
                        </Button>
                    </VStack>
                </Box>

                <Text textAlign="center">
                    {t('dontHaveAccount')}{' '}
                    <Link as={RouterLink} to="/signup" color="blue.500">
                        {t('signUp')}
                    </Link>
                </Text>
            </VStack>
        </Container>
    );
};

export default Login;