import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Discover from './pages/Discover';
import Browse from './pages/Browse';
import Chat from './pages/Chat';
import Profile from './pages/Profile';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Onboarding from './pages/Onboarding';
import Landing from './pages/Landing';
import { useAuth } from './contexts/AuthContext';

const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
    const { isAuthenticated, user } = useAuth();

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    // If user is authenticated but hasn't completed their profile,
    // redirect to onboarding unless they're already there
    if (!user?.profileCompleted && !window.location.pathname.includes('/onboarding')) {
        return <Navigate to="/onboarding" replace />;
    }

    return element;
};

const PublicRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
    const { isAuthenticated, user } = useAuth();

    // If user is authenticated and hasn't completed profile, redirect to onboarding
    if (isAuthenticated && !user?.profileCompleted) {
        return <Navigate to="/onboarding" replace />;
    }

    // If user is authenticated and has completed profile, redirect to discover
    if (isAuthenticated && user?.profileCompleted) {
        return <Navigate to="/discover" replace />;
    }

    return element;
};

const AppRoutes: React.FC = () => {
    return (
        <Routes>
            {/* Public Routes */}
            <Route path="/" element={<PublicRoute element={<Landing />} />} />
            <Route path="/login" element={<PublicRoute element={<Login />} />} />
            <Route path="/signup" element={<PublicRoute element={<SignUp />} />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            {/* Protected Routes */}
            <Route path="/discover" element={<PrivateRoute element={<Discover />} />} />
            <Route path="/browse" element={<PrivateRoute element={<Browse />} />} />
            <Route path="/chat" element={<PrivateRoute element={<Chat />} />} />
            <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
            <Route path="/onboarding" element={<PrivateRoute element={<Onboarding />} />} />

            {/* Catch all route */}
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};

export default AppRoutes;