import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    useColorModeValue,
    Divider,
    List,
    ListItem,
    ListIcon,
} from '@chakra-ui/react';
import { FaShieldAlt, FaUserLock, FaDatabase, FaGlobe, FaTrash, FaEnvelope } from 'react-icons/fa';
import { useLanguage } from '../contexts/LanguageContext';

const PrivacyPolicy: React.FC = () => {
    const { language } = useLanguage();
    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const subTextColor = useColorModeValue('gray.600', 'gray.400');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    const content = {
        English: {
            title: 'Privacy Policy',
            lastUpdated: 'Last Updated: February 2024',
            sections: [
                {
                    title: 'Information We Collect',
                    content: 'We collect information you provide directly to us, including:',
                    items: [
                        'Profile information (name, age, photos, interests, location)',
                        'Communication data (messages, matches, likes)',
                        'Usage information (login times, features used)',
                        'Device information (IP address, browser type)',
                    ]
                },
                {
                    title: 'How We Use Your Information',
                    content: 'Your information helps us provide and improve our dating services:',
                    items: [
                        'Match you with compatible partners',
                        'Improve our matching algorithms',
                        'Ensure platform safety and prevent abuse',
                        'Send notifications about matches and messages',
                    ]
                },
                {
                    title: 'Data Protection',
                    content: 'We implement strong security measures to protect your data:',
                    items: [
                        'End-to-end encryption for messages',
                        'Secure data storage and regular backups',
                        'Regular security audits and updates',
                        'Strict access controls for employee data access',
                    ]
                },
                {
                    title: 'Your Privacy Rights',
                    content: 'You have control over your personal information:',
                    items: [
                        'Access and download your personal data',
                        'Request deletion of your account and data',
                        'Control your visibility and matching preferences',
                        'Manage notification and communication settings',
                    ]
                },
                {
                    title: 'Contact Us',
                    content: 'For privacy concerns or questions, contact us at:',
                    items: [
                        'Email: privacy@datingapp.com',
                        'Address: 123 Privacy Street, Tech City',
                        'Response time: Within 48 hours',
                    ]
                }
            ]
        },
        Français: {
            title: 'Politique de Confidentialité',
            lastUpdated: 'Dernière mise à jour : Février 2024',
            sections: [
                {
                    title: 'Informations Collectées',
                    content: 'Nous collectons les informations que vous nous fournissez directement :',
                    items: [
                        'Informations de profil (nom, âge, photos, centres d\'intérêt, localisation)',
                        'Données de communication (messages, matchs, likes)',
                        'Informations d\'utilisation (temps de connexion, fonctionnalités utilisées)',
                        'Informations sur l\'appareil (adresse IP, type de navigateur)',
                    ]
                },
                {
                    title: 'Utilisation de Vos Informations',
                    content: 'Vos informations nous aident à fournir et améliorer nos services de rencontre :',
                    items: [
                        'Vous mettre en relation avec des partenaires compatibles',
                        'Améliorer nos algorithmes de matching',
                        'Assurer la sécurité de la plateforme et prévenir les abus',
                        'Envoyer des notifications sur les matchs et messages',
                    ]
                },
                {
                    title: 'Protection des Données',
                    content: 'Nous mettons en œuvre des mesures de sécurité strictes pour protéger vos données :',
                    items: [
                        'Chiffrement de bout en bout pour les messages',
                        'Stockage sécurisé des données et sauvegardes régulières',
                        'Audits de sécurité et mises à jour régulières',
                        'Contrôles d\'accès stricts pour l\'accès aux données par les employés',
                    ]
                },
                {
                    title: 'Vos Droits de Confidentialité',
                    content: 'Vous avez le contrôle sur vos informations personnelles :',
                    items: [
                        'Accéder et télécharger vos données personnelles',
                        'Demander la suppression de votre compte et de vos données',
                        'Contrôler votre visibilité et vos préférences de matching',
                        'Gérer vos paramètres de notification et de communication',
                    ]
                },
                {
                    title: 'Nous Contacter',
                    content: 'Pour toute question sur la confidentialité, contactez-nous :',
                    items: [
                        'Email : privacy@datingapp.com',
                        'Adresse : 123 rue de la Confidentialité, Ville Tech',
                        'Délai de réponse : Sous 48 heures',
                    ]
                }
            ]
        }
    };

    const currentContent = content[language];

    return (
        <Container maxW="container.md" py={8}>
            <VStack spacing={8} align="stretch">
                <Box textAlign="center" mb={8}>
                    <Heading size="xl" mb={4} color={textColor}>
                        {currentContent.title}
                    </Heading>
                    <Text color={subTextColor}>
                        {currentContent.lastUpdated}
                    </Text>
                </Box>

                <Box bg={bgColor} p={6} borderRadius="lg" shadow="md" borderWidth="1px" borderColor={borderColor}>
                    <Accordion allowMultiple>
                        {currentContent.sections.map((section, index) => (
                            <AccordionItem key={index} border="none" mb={4}>
                                <AccordionButton 
                                    _hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
                                    borderRadius="md"
                                >
                                    <Box flex="1" textAlign="left" fontWeight="semibold" color={textColor}>
                                        {section.title}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Text color={textColor} mb={4}>
                                        {section.content}
                                    </Text>
                                    <List spacing={3}>
                                        {section.items.map((item, itemIndex) => (
                                            <ListItem 
                                                key={itemIndex}
                                                color={subTextColor}
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <ListIcon 
                                                    as={
                                                        index === 0 ? FaUserLock :
                                                        index === 1 ? FaDatabase :
                                                        index === 2 ? FaShieldAlt :
                                                        index === 3 ? FaGlobe :
                                                        index === 4 ? FaEnvelope : FaTrash
                                                    }
                                                    color="brand.500"
                                                />
                                                {item}
                                            </ListItem>
                                        ))}
                                    </List>
                                </AccordionPanel>
                                {index < currentContent.sections.length - 1 && (
                                    <Divider my={4} borderColor={borderColor} />
                                )}
                            </AccordionItem>
                        ))}
                    </Accordion>
                </Box>
            </VStack>
        </Container>
    );
};

export default PrivacyPolicy; 