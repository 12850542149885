import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    VStack,
    HStack,
    Text,
    Avatar,
    useColorModeValue,
    Spinner,
    useToast,
    Grid,
    GridItem,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Badge,
    Container,
    Heading,
    SimpleGrid,
} from '@chakra-ui/react';
import {
    FaEdit,
    FaSignOutAlt,
    FaBell,
    FaUserShield,
    FaWineGlass,
    FaSmoking,
} from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import { userService } from '../services/userService';

export default function Profile() {
    const { user, isLoading, logout } = useAuth();
    const navigate = useNavigate();
    const toast = useToast();

    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const subTextColor = useColorModeValue('gray.600', 'gray.400');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const iconColor = useColorModeValue('blue.500', 'blue.300');
    const menuBg = useColorModeValue('white', 'gray.800');
    const menuBorderColor = useColorModeValue('gray.200', 'gray.600');

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout error:', error);
            toast({
                title: 'Error',
                description: 'Failed to logout. Please try again.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minH="100vh">
                <Spinner size="xl" color="blue.500" />
            </Box>
        );
    }

    if (!user) {
        navigate('/login');
        return null;
    }

    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} py={8}>
            <Container maxW="container.lg">
                <Grid templateColumns={{ base: '1fr', md: '300px 1fr' }} gap={8}>
                    {/* Left Column - Profile Info */}
                    <GridItem>
                        <Box bg={bgColor} borderRadius="lg" boxShadow="md" p={6}>
                            <VStack spacing={4} align="center">
                                <Box position="relative">
                                    <Avatar 
                                        size="2xl" 
                                        src={user.photos?.[0] || undefined}
                                        name={user.name || 'User'} 
                                    />
                                    <IconButton
                                        aria-label="Edit profile picture"
                                        icon={<FaEdit />}
                                        size="sm"
                                        colorScheme="blue"
                                        position="absolute"
                                        bottom={0}
                                        right={0}
                                        rounded="full"
                                    />
                                </Box>
                                <VStack spacing={1}>
                                    <Heading size="md" color={textColor}>{user.name || 'User'}</Heading>
                                    <Text color={subTextColor}>{user.email}</Text>
                                </VStack>
                                {user.interests && user.interests.length > 0 && (
                                    <HStack spacing={2} mt={2} flexWrap="wrap" justify="center">
                                        {user.interests.map((interest, index) => (
                                            <Badge key={index} colorScheme="blue" variant="subtle">
                                                {interest}
                                            </Badge>
                                        ))}
                                    </HStack>
                                )}
                            </VStack>

                            <VStack mt={6} spacing={3} align="stretch">
                                <Button 
                                    leftIcon={<FaEdit />}
                                    variant="outline" 
                                    w="full"
                                >
                                    Edit Profile
                                </Button>
                                <Menu>
                                    <MenuButton
                                        as={Button}
                                        rightIcon={<FaBell />}
                                        variant="outline"
                                        w="full"
                                    >
                                        Notifications
                                    </MenuButton>
                                    <MenuList bg={menuBg} borderColor={menuBorderColor}>
                                        <MenuItem icon={<FaBell />}>
                                            All Notifications
                                        </MenuItem>
                                        <MenuItem icon={<FaUserShield />}>
                                            Privacy Settings
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                                <Button 
                                    leftIcon={<FaSignOutAlt />}
                                    colorScheme="red" 
                                    variant="outline"
                                    w="full"
                                    onClick={handleLogout}
                                >
                                    Logout
                                </Button>
                            </VStack>
                        </Box>
                    </GridItem>

                    {/* Right Column - Profile Details */}
                    <GridItem>
                        <Box bg={bgColor} borderRadius="lg" boxShadow="md" p={6}>
                            <VStack spacing={6} align="stretch">
                                <Box>
                                    <Heading size="md" color={textColor} mb={3}>About Me</Heading>
                                    <Text color={textColor}>{user.bio || 'No bio yet'}</Text>
                                </Box>

                                {user.interests && user.interests.length > 0 && (
                                    <Box>
                                        <Heading size="md" color={textColor} mb={3}>Interests</Heading>
                                        <SimpleGrid columns={3} spacing={2}>
                                            {user.interests.map((interest, index) => (
                                                <Badge 
                                                    key={index}
                                                    colorScheme="blue" 
                                                    p={2} 
                                                    borderRadius="md"
                                                    textAlign="center"
                                                >
                                                    {interest}
                                                </Badge>
                                            ))}
                                        </SimpleGrid>
                                    </Box>
                                )}

                                {user.lifestyle && Object.keys(user.lifestyle).length > 0 && (
                                    <Box>
                                        <Heading size="md" color={textColor} mb={3}>Lifestyle</Heading>
                                        <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4}>
                                            {Object.entries(user.lifestyle).map(([key, value]) => (
                                                <HStack key={key} spacing={2}>
                                                    {key === 'drinking' && <FaWineGlass color={iconColor} />}
                                                    {key === 'smoking' && <FaSmoking color={iconColor} />}
                                                    <Text color={textColor} textTransform="capitalize">
                                                        {key}: {value}
                                                    </Text>
                                                </HStack>
                                            ))}
                                        </SimpleGrid>
                                    </Box>
                                )}
                            </VStack>
                        </Box>
                    </GridItem>
                </Grid>
            </Container>
        </Box>
    );
}
