import api, { ApiError } from './api';

export interface AuthResponse {
    message: string;
    token: string;
    user: {
        id: string;
        email: string;
        name: string;
        onboardingCompleted: boolean;
    };
}

export class AuthError extends Error {
    constructor(
        message: string,
        public status?: number,
        public code?: string
    ) {
        super(message);
        this.name = 'AuthError';
    }
}

export const authService = {
    isAuthenticated(): boolean {
        return !!localStorage.getItem('token');
    },

    getToken(): string | null {
        return localStorage.getItem('token');
    },

    setToken(token: string): void {
        localStorage.setItem('token', token);
    },

    clearToken(): void {
        localStorage.removeItem('token');
    },

    async login(email: string, password: string): Promise<AuthResponse> {
        try {
            console.log('Sending login request...');
            const response = await api.post<AuthResponse>('/auth/login', {
                email,
                password,
            });

            console.log('Raw login response:', response.data);
            const { token, user } = response.data;
            
            if (!token) {
                throw new AuthError('No token received from server');
            }

            console.log('Setting token and returning response');
            this.setToken(token);
            return response.data;
        } catch (error) {
            console.error('Login error in service:', error);
            if (error instanceof ApiError) {
                throw new AuthError(
                    error.message || 'Login failed',
                    error.status,
                    error.code
                );
            }
            throw new AuthError('An unexpected error occurred during login');
        }
    },

    async register(name: string, email: string, password: string): Promise<AuthResponse> {
        try {
            const response = await api.post<AuthResponse>('/auth/register', {
                name,
                email,
                password,
            });

            if (!response.data.token) {
                throw new AuthError('No token received from server');
            }

            this.setToken(response.data.token);
            return response.data;
        } catch (error) {
            console.error('Registration error:', error);
            if (error instanceof ApiError) {
                throw new AuthError(
                    error.message || 'Registration failed',
                    error.status,
                    error.code
                );
            }
            throw new AuthError('An unexpected error occurred during registration');
        }
    },

    async logout(): Promise<void> {
        try {
            // Clear token immediately to prevent any subsequent requests
            this.clearToken();
        } catch (error) {
            console.error('Logout error:', error);
            // Always clear token even if the request fails
            this.clearToken();
            throw error;
        }
    },

    async refreshToken(): Promise<string> {
        try {
            const response = await api.post<{ token: string }>('/auth/refresh');
            
            if (!response.data.token) {
                throw new AuthError('No token received from server');
            }

            this.setToken(response.data.token);
            return response.data.token;
        } catch (error) {
            console.error('Token refresh error:', error);
            this.clearToken();
            if (error instanceof ApiError) {
                throw new AuthError(
                    error.message || 'Token refresh failed',
                    error.status,
                    error.code
                );
            }
            throw new AuthError('An unexpected error occurred during token refresh');
        }
    }
};