import * as React from 'react';
import {
    Box,
    Grid,
    VStack,
    HStack,
    Text,
    Input,
    Button,
    Avatar,
    useColorModeValue,
    IconButton,
    Tag as Badge,
} from '@chakra-ui/react';
import { FaPaperPlane, FaSearch } from 'react-icons/fa';
import { useLanguage } from '../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';

interface ChatUser {
    id: number;
    name: string;
    imageUrl: string;
    lastMessage: string;
    timestamp: string;
    unread: number;
    online: boolean;
    lastSeen?: string;
}

interface Message {
    id: number;
    text: string;
    sender: 'user' | 'other';
    timestamp: Date;
}

const Chat: React.FC = () => {
    const { t } = useLanguage();
    const location = useLocation();
    
    // Color mode values
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const textColor = useColorModeValue('gray.800', 'white');
    const subTextColor = useColorModeValue('gray.600', 'gray.400');
    const sidebarBg = useColorModeValue('gray.50', 'gray.900');
    const hoverBg = useColorModeValue('gray.100', 'gray.700');
    const userMessageBg = useColorModeValue('brand.500', 'blue.600');
    const otherMessageBg = useColorModeValue('gray.100', 'gray.700');
    const messageTextColor = useColorModeValue('white', 'white');
    
    // States
    const [selectedUser, setSelectedUser] = React.useState<ChatUser | null>(null);
    const [newMessage, setNewMessage] = React.useState('');
    const [searchQuery, setSearchQuery] = React.useState('');
    
    // Mock data for chat users
    const [chatUsers] = React.useState<ChatUser[]>([
        {
            id: 1,
            name: "Sarah Johnson",
            imageUrl: "https://images.unsplash.com/photo-1494790108377-be9c29b29330",
            lastMessage: "Hey, how are you?",
            timestamp: "2 min ago",
            unread: 2,
            online: true
        },
        {
            id: 2,
            name: "Michael Chen",
            imageUrl: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d",
            lastMessage: "Would you like to meet for coffee?",
            timestamp: "1 hour ago",
            unread: 0,
            online: false,
            lastSeen: "30 min ago"
        },
        {
            id: 3,
            name: "Emma Wilson",
            imageUrl: "https://images.unsplash.com/photo-1524504388940-b1c1722653e1",
            lastMessage: "That sounds great! 😊",
            timestamp: "3 hours ago",
            unread: 1,
            online: true
        }
    ]);

    // Mock messages for the selected chat
    const [messages, setMessages] = React.useState<Message[]>([
        {
            id: 1,
            text: "Hi there! I noticed we share an interest in photography. What kind of photos do you like to take?",
            sender: 'other',
            timestamp: new Date(Date.now() - 3600000)
        },
        {
            id: 2,
            text: "Hey! Yes, I love photography! I mostly do landscape and street photography. How about you?",
            sender: 'user',
            timestamp: new Date(Date.now() - 1800000)
        },
        {
            id: 3,
            text: "That's awesome! I'm more into portrait photography, but I've been wanting to try landscape photography. Any tips for a beginner?",
            sender: 'other',
            timestamp: new Date(Date.now() - 900000)
        }
    ]);

    // Handle new chat user from navigation
    React.useEffect(() => {
        if (location.state?.chatUser) {
            const newUser = {
                id: location.state.chatUser.id,
                name: location.state.chatUser.name,
                imageUrl: location.state.chatUser.imageUrl,
                lastMessage: "",
                timestamp: "Just now",
                unread: 0,
                online: true
            };
            setSelectedUser(newUser);
        }
    }, [location.state]);

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            setMessages(prev => [...prev, {
                id: prev.length + 1,
                text: newMessage,
                sender: 'user',
                timestamp: new Date()
            }]);
            setNewMessage('');
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    const filteredUsers = chatUsers.filter(user =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    
    return (
        <Grid
            templateColumns={{ base: "1fr", md: "350px 1fr" }}
            h="calc(100vh - 64px)"
            gap={0}
        >
            {/* Sidebar */}
            <Box
                borderRight="1px"
                borderColor={borderColor}
                bg={sidebarBg}
                overflowY="auto"
                display={{ base: selectedUser ? 'none' : 'block', md: 'block' }}
            >
                <VStack spacing={4} p={4}>
                    <HStack w="full">
                        <Input
                            placeholder={t('searchConversations')}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            bg={bgColor}
                            borderRadius="full"
                            pl={4}
                        />
                        <IconButton
                            aria-label="Search"
                            icon={<FaSearch />}
                            borderRadius="full"
                            colorScheme="brand"
                        />
                    </HStack>

                    <VStack spacing={2} w="full" align="stretch">
                        {filteredUsers.map(user => (
                            <Box
                                key={user.id}
                                p={3}
                                cursor="pointer"
                                borderRadius="lg"
                                bg={selectedUser?.id === user.id ? hoverBg : 'transparent'}
                                _hover={{ bg: hoverBg }}
                                onClick={() => setSelectedUser(user)}
                            >
                                <HStack spacing={3}>
                                    <Box position="relative">
                                        <Avatar
                                            size="md"
                                            name={user.name}
                                            src={user.imageUrl}
                                        />
                                        {user.online && (
                                            <Box
                                                position="absolute"
                                                bottom="2px"
                                                right="2px"
                                                bg="green.400"
                                                borderRadius="full"
                                                w="6px"
                                                h="6px"
                                                border="1.5px solid"
                                                borderColor={bgColor}
                                            />
                                        )}
                                    </Box>
                                    <VStack
                                        align="start"
                                        spacing={0}
                                        flex={1}
                                        overflow="hidden"
                                    >
                                        <HStack justify="space-between" w="full">
                                            <Text
                                                fontWeight="semibold"
                                                color={textColor}
                                                noOfLines={1}
                                            >
                                                {user.name}
                                            </Text>
                                            <Text
                                                fontSize="xs"
                                                color={subTextColor}
                                            >
                                                {user.timestamp}
                                            </Text>
                                        </HStack>
                                        <HStack justify="space-between" w="full">
                                            <Text
                                                fontSize="sm"
                                                color={subTextColor}
                                                noOfLines={1}
                                            >
                                                {user.lastMessage}
                                            </Text>
                                            {user.unread > 0 && (
                                                <Badge
                                                    colorScheme="brand"
                                                    borderRadius="full"
                                                    px={2}
                                                >
                                                    {user.unread}
                                                </Badge>
                                            )}
                                        </HStack>
                                    </VStack>
                                </HStack>
                            </Box>
                        ))}
                    </VStack>
                </VStack>
            </Box>

            {/* Chat Area */}
            <Box
                bg={bgColor}
                display={{ base: selectedUser ? 'block' : 'none', md: 'block' }}
            >
                {selectedUser ? (
                    <VStack h="full" spacing={0}>
                        {/* Chat Header */}
                        <HStack
                            w="full"
                            p={4}
                            borderBottom="1px"
                            borderColor={borderColor}
                            spacing={4}
                        >
                            <Avatar
                                size="md"
                                name={selectedUser.name}
                                src={selectedUser.imageUrl}
                            />
                            <VStack align="start" spacing={0} flex={1}>
                                <Text fontWeight="semibold" color={textColor}>
                                    {selectedUser.name}
                                </Text>
                                <Text fontSize="sm" color={subTextColor}>
                                    {selectedUser.online ? t('online') : 
                                     selectedUser.lastSeen ? `${t('lastSeen')} ${selectedUser.lastSeen}` : 
                                     t('offline')}
                                </Text>
                            </VStack>
                        </HStack>

                        {/* Messages */}
                        <Box
                            flex={1}
                            overflowY="auto"
                            p={4}
                        >
                            <VStack spacing={4} align="stretch">
                                {messages.map(message => (
                                    <Box
                                        key={message.id}
                                        alignSelf={message.sender === 'user' ? 'flex-end' : 'flex-start'}
                                        maxW="70%"
                                    >
                                        <HStack spacing={2} align="flex-start">
                                            {message.sender === 'other' && (
                                                <Avatar
                                                    size="sm"
                                                    name={selectedUser.name}
                                                    src={selectedUser.imageUrl}
                                                />
                                            )}
                                            <VStack
                                                spacing={1}
                                                align={message.sender === 'user' ? 'flex-end' : 'flex-start'}
                                            >
                                                <Box
                                                    bg={message.sender === 'user' ? userMessageBg : otherMessageBg}
                                                    color={messageTextColor}
                                                    px={4}
                                                    py={2}
                                                    borderRadius="lg"
                                                    shadow="sm"
                                                >
                                                    <Text>{message.text}</Text>
                                                </Box>
                                                <Text fontSize="xs" color={subTextColor} px={1}>
                                                    {message.timestamp.toLocaleTimeString([], {
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    })}
                                                </Text>
                                            </VStack>
                                            {message.sender === 'user' && (
                                                <Avatar
                                                    size="sm"
                                                    name="You"
                                                    src="https://bit.ly/dan-abramov"
                                                />
                                            )}
                                        </HStack>
                                    </Box>
                                ))}
                            </VStack>
                        </Box>

                        {/* Message Input */}
                        <Box p={4} borderTop="1px" borderColor={borderColor}>
                            <HStack spacing={3} w="full">
                                <Input
                                    placeholder={t('typeMessage')}
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                    borderRadius="full"
                                    size="md"
                                    flex="1"
                                    minW={{ base: "200px", md: "300px", lg: "400px" }}
                                />
                                <Button
                                    colorScheme="brand"
                                    borderRadius="full"
                                    onClick={handleSendMessage}
                                    disabled={!newMessage.trim()}
                                    size="md"
                                >
                                    <FaPaperPlane />
                                </Button>
                            </HStack>
                        </Box>
                    </VStack>
                ) : (
                    <VStack
                        h="full"
                        justify="center"
                        align="center"
                        spacing={4}
                        p={4}
                        display={{ base: 'none', md: 'flex' }}
                    >
                        <Text
                            fontSize="xl"
                            fontWeight="medium"
                            color={textColor}
                            textAlign="center"
                        >
                            {t('selectConversation')}
                        </Text>
                        <Text
                            color={subTextColor}
                            textAlign="center"
                        >
                            {t('chooseChat')}
                        </Text>
                    </VStack>
                )}
            </Box>
        </Grid>
    );
};

export default Chat;
