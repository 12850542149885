import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/authService';
import { userService } from '../services/userService';
import type { UserProfile } from '../services/userService';

interface AuthContextType {
    isAuthenticated: boolean;
    user: UserProfile | null;
    isLoading: boolean;
    error: string | null;
    login: (email: string, password: string) => Promise<void>;
    register: (name: string, email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    clearError: () => void;
    updateProfile: (data: Partial<UserProfile>) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<UserProfile | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const clearError = useCallback(() => setError(null), []);

    const fetchUser = useCallback(async () => {
        if (!authService.getToken()) {
            setIsLoading(false);
            return;
        }
        
        try {
            const userData = await userService.getCurrentUser();
            setUser(userData);
            setIsAuthenticated(true);
            setError(null);
        } catch (err) {
            console.error('Error fetching user:', err);
            setIsAuthenticated(false);
            setUser(null);
            authService.clearToken();
        } finally {
            setIsLoading(false);
        }
    }, []);

    // Single effect to handle initial auth check
    useEffect(() => {
        const initAuth = async () => {
            try {
                const token = authService.getToken();
                if (!token) {
                    setIsAuthenticated(false);
                    setUser(null);
                } else {
                    const userData = await userService.getCurrentUser();
                    setUser(userData);
                    setIsAuthenticated(true);
                }
            } catch (err) {
                console.error('Error during auth initialization:', err);
                authService.clearToken();
                setIsAuthenticated(false);
                setUser(null);
            } finally {
                setIsLoading(false);
            }
        };

        initAuth();
    }, []); // Only run on mount

    const login = async (email: string, password: string) => {
        setIsLoading(true);
        setError(null);
        
        try {
            const response = await authService.login(email, password);
            setUser(response.user);
            setIsAuthenticated(true);
            // Let the router handle navigation
        } catch (err) {
            console.error('Login error:', err);
            setError(err.message || 'Failed to login');
            setIsAuthenticated(false);
            setUser(null);
        } finally {
            setIsLoading(false);
        }
    };

    const register = async (name: string, email: string, password: string) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authService.register(name, email, password);
            setIsAuthenticated(true);
            setUser(response.user);
            navigate('/onboarding');
        } catch (err: any) {
            setIsAuthenticated(false);
            setUser(null);
            setError(err.message || 'An error occurred during registration');
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    const logout = async () => {
        try {
            authService.clearToken();
            setUser(null);
            setIsAuthenticated(false);
            // Let the router handle navigation
        } catch (error) {
            console.error('Logout error:', error);
            setError('Failed to logout');
        }
    };

    const updateProfile = async (data: Partial<UserProfile>) => {
        try {
            console.log('Updating profile with data:', data);
            const updatedUser = await userService.updateProfile(data);
            console.log('Profile update response:', updatedUser);
            
            // Update the local user state with the new data
            setUser(prevUser => ({
                ...prevUser!,
                ...updatedUser,
                onboardingCompleted: true
            }));
            
            console.log('User state after update:', user);
            navigate('/profile');
        } catch (err) {
            console.error('Profile update error:', err);
            throw err;
        }
    };

    const value = {
        isAuthenticated,
        user,
        isLoading,
        error,
        login,
        register,
        logout,
        clearError,
        updateProfile
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
