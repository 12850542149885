import React, { useState } from 'react';
import {
    Box,
    Container,
    Grid,
    VStack,
    HStack,
    Text,
    Button,
    Image,
    Select,
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
    Input,
    IconButton,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Tag,
    Wrap,
    WrapItem,
    useToast,
    useColorModeValue,
} from '@chakra-ui/react';
import { FaSlidersH, FaMapMarkerAlt, FaComments, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';

interface Profile {
    id: number;
    name: string;
    age: number;
    location: string;
    bio: {
        English: string;
        Français: string;
    };
    imageUrl: string;
    interests: string[];
    gender: string;
}

const dummyProfiles: Profile[] = [
    {
        id: 1,
        name: 'Sarah Johnson',
        age: 28,
        location: 'New York, NY',
        bio: {
            English: "Adventure seeker and coffee enthusiast. Love hiking and photography.",
            Français: "Aventurière et passionnée de café. J'adore la randonnée et la photographie."
        },
        imageUrl: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
        interests: ['Photography', 'Hiking', 'Coffee', 'Travel'],
        gender: 'Female'
    },
    {
        id: 2,
        name: 'Michael Chen',
        age: 31,
        location: 'San Francisco, CA',
        bio: {
            English: "Tech entrepreneur who loves hiking and trying new restaurants.",
            Français: "Entrepreneur technologique qui aime la randonnée et essayer de nouveaux restaurants."
        },
        imageUrl: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d',
        interests: ['Technology', 'Hiking', 'Food', 'Startups'],
        gender: 'Male'
    },
    {
        id: 3,
        name: 'Emma Wilson',
        age: 26,
        location: 'London, UK',
        bio: {
            English: "Art director with a passion for travel and photography.",
            Français: "Directeur artistique passionné pour le voyage et la photographie."
        },
        imageUrl: 'https://images.unsplash.com/photo-1524504388940-b1c1722653e1',
        interests: ['Art', 'Design', 'Travel', 'Photography'],
        gender: 'Female'
    },
    {
        id: 4,
        name: 'James Rodriguez',
        age: 29,
        location: 'Miami, FL',
        bio: {
            English: "Music producer and beach volleyball enthusiast.",
            Français: "Producteur de musique et passionné de beach volleyball."
        },
        imageUrl: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e',
        interests: ['Music', 'Sports', 'Beach', 'Fitness'],
        gender: 'Male'
    },
    {
        id: 5,
        name: 'Sophia Lee',
        age: 27,
        location: 'Seattle, WA',
        bio: {
            English: "Book lover, yoga instructor, and coffee addict.",
            Français: "Amoir des livres, instructrice de yoga et caféine."
        },
        imageUrl: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80',
        interests: ['Yoga', 'Reading', 'Coffee', 'Meditation'],
        gender: 'Female'
    },
    {
        id: 6,
        name: 'David Kim',
        age: 30,
        location: 'Chicago, IL',
        bio: {
            English: "Professional chef with a love for rock climbing.",
            Français: "Chef professionnel passionné pour le grimpeur."
        },
        imageUrl: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d',
        interests: ['Cooking', 'Climbing', 'Food', 'Adventure'],
        gender: 'Male'
    },
    {
        id: 7,
        name: 'Olivia Martinez',
        age: 25,
        location: 'Austin, TX',
        bio: {
            English: "Indie musician and vintage fashion enthusiast.",
            Français: "Musicienne indépendante et passionnée de mode vintage."
        },
        imageUrl: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb',
        interests: ['Music', 'Fashion', 'Art', 'Vintage'],
        gender: 'Female'
    },
    {
        id: 8,
        name: 'Alex Thompson',
        age: 32,
        location: 'Portland, OR',
        bio: {
            English: "Environmental scientist and craft beer lover.",
            Français: "Scientifique environnemental et amateur de bière artisanale."
        },
        imageUrl: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d',
        interests: ['Environment', 'Beer', 'Hiking', 'Science'],
        gender: 'Non-binary'
    },
    {
        id: 9,
        name: 'Isabella Garcia',
        age: 28,
        location: 'Barcelona, Spain',
        bio: {
            English: "Dance instructor and travel blogger exploring the world.",
            Français: "Instructrice de danse et blogueuse de voyage à la découverte du monde."
        },
        imageUrl: 'https://images.unsplash.com/photo-1524504388940-b1c1722653e1',
        interests: ['Dance', 'Travel', 'Writing', 'Photography'],
        gender: 'Female'
    },
    {
        id: 10,
        name: 'Lucas Silva',
        age: 29,
        location: 'Rio de Janeiro, Brazil',
        bio: {
            English: "Surfer and environmental activist making waves.",
            Français: "Surfeur et activiste environnemental faisant des vagues."
        },
        imageUrl: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e',
        interests: ['Surfing', 'Environment', 'Beach', 'Yoga'],
        gender: 'Male'
    }
];

const Browse: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    useDisclosure();
    // eslint-disable-next-line no-empty-pattern
    const [] = useState<Profile | null>(null);
    const [ageRange, setAgeRange] = useState<[number, number]>([18, 50]);
    const [location, setLocation] = useState<string>('');
    const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
    const [selectedGender, setSelectedGender] = useState<string>('');
    const navigate = useNavigate();
    const toast = useToast();
    const { t, language } = useLanguage();

    const allInterests = Array.from(
        new Set(dummyProfiles.flatMap(profile => profile.interests))
    ).sort();

    const genderOptions = [t('all'), t('male'), t('female'), t('nonBinary')];

    const toggleInterest = (interest: string) => {
        setSelectedInterests(prev =>
            prev.includes(interest)
                ? prev.filter(i => i !== interest)
                : [...prev, interest]
        );
    };

    const handleAgeRangeChange = (val: number[]) => {
        setAgeRange([val[0], val[1]]);
    };

    const filteredProfiles = dummyProfiles.filter(profile => {
        const ageMatch = profile.age >= ageRange[0] && profile.age <= ageRange[1];
        const locationMatch = !location || profile.location.toLowerCase().includes(location.toLowerCase());
        const interestsMatch = selectedInterests.length === 0 || 
            selectedInterests.some(interest => profile.interests.includes(interest));
        const genderMatch = !selectedGender || selectedGender === 'All' || profile.gender === selectedGender;
        return ageMatch && locationMatch && interestsMatch && genderMatch;
    });

    const handleViewProfile = (profile: Profile) => {
        navigate(`/profile/${profile.id}`);
    };

    const handleChat = (profile: Profile) => {
        navigate('/chat', { state: { chatUser: profile } });
        toast({
            title: 'Starting chat',
            description: `Opening chat with ${profile.name}`,
            status: 'success',
            duration: 2000,
            isClosable: true,
        });
    };

    // Color mode values
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const textColor = useColorModeValue('gray.800', 'white');
    const subTextColor = useColorModeValue('gray.600', 'gray.300');
    const cardBg = useColorModeValue('white', 'gray.700');
    const hoverBg = useColorModeValue('gray.50', 'gray.600');
    const drawerBg = useColorModeValue('white', 'gray.800');

    return (
        <Box 
            minH="calc(100vh - 60px)"
            w="100vw"
            position="relative"
            left="50%"
            right="50%"
            mx="-50vw"
            bg={useColorModeValue('gray.50', 'gray.900')}
            overflow="hidden"
        >
            <Box px={{ base: 4, md: 8, lg: 20, xl: 32 }} py={6}>
                <HStack 
                    justify="space-between" 
                    mb={8} 
                    borderBottom="1px" 
                    borderColor={borderColor}
                    pb={4}
                    maxW="1600px"
                    mx="auto"
                    px={6}
                >
                    <VStack align="start" spacing={2}>
                        <Text 
                            fontSize={{ base: "2xl", lg: "4xl" }} 
                            fontWeight="bold" 
                            color={textColor}
                        >
                            {t('browseProfiles')}
                        </Text>
                        <Text 
                            fontSize={{ base: "md", lg: "lg" }} 
                            color={subTextColor}
                        >
                            {filteredProfiles.length} {t('profilesFound')}
                        </Text>
                    </VStack>
                    <Button
                        leftIcon={<FaSlidersH />}
                        onClick={onOpen}
                        colorScheme="brand"
                        variant="outline"
                        size="lg"
                        fontSize="md"
                        px={8}
                    >
                        {t('filters')}
                    </Button>
                </HStack>

                {/* Filters Drawer */}
                <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
                    <DrawerOverlay />
                    <DrawerContent bg={drawerBg}>
                        <DrawerCloseButton />
                        <DrawerHeader color={textColor}>{t('customizeSearch')}</DrawerHeader>

                        <DrawerBody>
                            <VStack spacing={6} align="stretch">
                                <Box>
                                    <Text mb={2} fontWeight="medium" color={textColor}>
                                        {t('gender')}
                                    </Text>
                                    <Select
                                        value={selectedGender}
                                        onChange={(e) => setSelectedGender(e.target.value)}
                                        bg={bgColor}
                                        borderColor={borderColor}
                                    >
                                        {genderOptions.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Select>
                                </Box>

                                <Box>
                                    <Text mb={2} fontWeight="medium" color={textColor}>
                                        {t('ageRange')}
                                    </Text>
                                    <Text fontSize="sm" color={subTextColor} mb={2}>
                                        {ageRange[0]} - {ageRange[1]} {t('yearsOld')}
                                    </Text>
                                    <RangeSlider
                                        min={18}
                                        max={80}
                                        step={1}
                                        value={ageRange}
                                        onChange={handleAgeRangeChange}
                                    >
                                        <RangeSliderTrack>
                                            <RangeSliderFilledTrack />
                                        </RangeSliderTrack>
                                        <RangeSliderThumb index={0} />
                                        <RangeSliderThumb index={1} />
                                    </RangeSlider>
                                </Box>

                                <Box>
                                    <Text mb={2} fontWeight="medium" color={textColor}>
                                        {t('location')}
                                    </Text>
                                    <Input
                                        placeholder={t('enterLocation')}
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                        bg={bgColor}
                                        color={textColor}
                                        borderColor={borderColor}
                                    />
                                </Box>

                                <Box>
                                    <Text mb={2} fontWeight="medium" color={textColor}>
                                        {t('interests')}
                                    </Text>
                                    <Wrap>
                                        {allInterests.map(interest => (
                                            <WrapItem key={interest}>
                                                <Tag
                                                    size="md"
                                                    variant={selectedInterests.includes(interest) ? "solid" : "outline"}
                                                    colorScheme="brand"
                                                    cursor="pointer"
                                                    onClick={() => toggleInterest(interest)}
                                                >
                                                    {interest}
                                                </Tag>
                                            </WrapItem>
                                        ))}
                                    </Wrap>
                                </Box>
                            </VStack>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>

                {/* Profile Grid */}
                <Grid
                    templateColumns={{ 
                        base: "repeat(1, 1fr)", 
                        md: "repeat(2, 1fr)", 
                        lg: "repeat(3, 1fr)",
                        xl: "repeat(4, 1fr)"
                    }}
                    gap={{ base: 4, md: 5, lg: 6 }}
                    maxW="1600px"
                    mx="auto"
                    px={6}
                    alignItems="stretch"
                >
                    {filteredProfiles.map(profile => (
                        <Box
                            key={profile.id}
                            borderWidth="1px"
                            borderRadius="lg"
                            overflow="hidden"
                            bg={cardBg}
                            borderColor={borderColor}
                            transition="all 0.2s ease-in-out"
                            _hover={{ 
                                transform: 'translateY(-2px)', 
                                shadow: 'sm',
                                borderColor: 'brand.500',
                            }}
                            display="flex"
                            flexDirection="column"
                            maxW={{ base: "100%", md: "280px" }}
                            mx="auto"
                        >
                            <Box
                                position="relative"
                                width="100%"
                                paddingBottom="100%"
                                overflow="hidden"
                                bg="gray.100"
                            >
                                <Image
                                    src={`${profile.imageUrl}?auto=format&fit=crop&w=400&h=400&q=80`}
                                    alt={profile.name}
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    width="100%"
                                    height="100%"
                                    objectFit="cover"
                                    objectPosition="center"
                                    transition="transform 0.2s ease-in-out"
                                    _hover={{ transform: 'scale(1.03)' }}
                                />
                            </Box>
                            <Box 
                                p={3}
                                bg={useColorModeValue('white', 'gray.800')}
                                flex="1"
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                            >
                                <VStack align="start" spacing={2} flex="1">
                                    <VStack align="start" spacing={0.5} w="100%">
                                        <Text 
                                            fontWeight="bold" 
                                            fontSize="md"
                                            color={textColor}
                                            lineHeight="1.2"
                                        >
                                            {profile.name}, {profile.age}
                                        </Text>
                                        <HStack fontSize="xs" color={subTextColor} spacing={1}>
                                            <FaMapMarkerAlt size="0.75em" />
                                            <Text>{profile.location}</Text>
                                        </HStack>
                                    </VStack>

                                    <Text 
                                        fontSize="xs"
                                        color={subTextColor} 
                                        noOfLines={2}
                                        lineHeight="1.4"
                                    >
                                        {profile.bio[language]}
                                    </Text>

                                    <Wrap spacing={1.5}>
                                        {profile.interests.slice(0, 3).map(interest => (
                                            <Tag 
                                                key={interest} 
                                                size="sm" 
                                                colorScheme="brand"
                                                px={2}
                                                py={0.5}
                                                borderRadius="full"
                                                fontSize="2xs"
                                            >
                                                {interest}
                                            </Tag>
                                        ))}
                                    </Wrap>
                                </VStack>

                                <VStack spacing={2} w="100%" pt={2}>
                                    <Button
                                        leftIcon={<FaUser size="0.8em" />}
                                        onClick={() => handleViewProfile(profile)}
                                        size="sm"
                                        w="100%"
                                        colorScheme="brand"
                                        variant="solid"
                                        borderRadius="full"
                                        h="2rem"
                                        fontSize="xs"
                                        _hover={{
                                            transform: 'translateY(-1px)',
                                            shadow: 'sm'
                                        }}
                                    >
                                        {t('viewProfile')}
                                    </Button>
                                    <Button
                                        leftIcon={<FaComments size="0.8em" />}
                                        onClick={() => handleChat(profile)}
                                        size="sm"
                                        w="100%"
                                        colorScheme="brand"
                                        variant="outline"
                                        borderRadius="full"
                                        h="2rem"
                                        fontSize="xs"
                                        _hover={{
                                            transform: 'translateY(-1px)',
                                            shadow: 'sm'
                                        }}
                                    >
                                        {t('startChat')}
                                    </Button>
                                </VStack>
                            </Box>
                        </Box>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default Browse; 