import React from 'react';
import {
    Box,
    Button,
    Container,
    Heading,
    Text,
    VStack,
    HStack,
    Image,
    SimpleGrid,
    Icon,
    useColorModeValue,
    Flex,
    Badge,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaHeart, FaSearch, FaComments, FaUserFriends } from 'react-icons/fa';

const Feature = ({ icon, title, description }: { icon: React.ElementType, title: string, description: string }) => {
    const featureBg = useColorModeValue('white', 'gray.800');
    const featureShadow = useColorModeValue('lg', 'dark-lg');
    const iconColor = useColorModeValue('blue.500', 'blue.300');
    
    return (
        <Box
            bg={featureBg}
            p={6}
            rounded="xl"
            shadow={featureShadow}
            transition="all 0.3s"
            _hover={{ transform: 'translateY(-5px)' }}
        >
            <Icon as={icon} w={10} h={10} color={iconColor} mb={4} />
            <Heading size="md" mb={2}>{title}</Heading>
            <Text color={useColorModeValue('gray.600', 'gray.400')}>{description}</Text>
        </Box>
    );
};

const Landing: React.FC = () => {
    const navigate = useNavigate();
    const bgGradient = useColorModeValue(
        'linear(to-b, blue.50, white)',
        'linear(to-b, gray.900, gray.800)'
    );
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const highlightColor = useColorModeValue('blue.500', 'blue.300');
    const buttonBg = useColorModeValue('blue.500', 'blue.400');
    const buttonHoverBg = useColorModeValue('blue.600', 'blue.500');

    return (
        <Box minH="100vh" bgGradient={bgGradient}>
            {/* Hero Section */}
            <Container maxW="container.xl" pt={{ base: 20, md: 32 }} pb={20}>
                <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="space-between">
                    <Box flex={1} pr={{ base: 0, md: 10 }} mb={{ base: 10, md: 0 }}>
                        <Badge
                            colorScheme="blue"
                            fontSize="sm"
                            mb={4}
                            px={3}
                            py={1}
                            rounded="full"
                        >
                            Find Love Today
                        </Badge>
                        <Heading
                            as="h1"
                            size="2xl"
                            mb={6}
                            color={headingColor}
                            lineHeight="shorter"
                        >
                            Discover Your Perfect{' '}
                            <Text as="span" color={highlightColor}>
                                Match
                            </Text>
                        </Heading>
                        <Text
                            fontSize="xl"
                            mb={8}
                            color={textColor}
                            lineHeight="tall"
                        >
                            Join our vibrant community and start your journey to finding meaningful connections. 
                            We use advanced matching algorithms to connect you with people who share your interests and values.
                        </Text>
                        <HStack spacing={4}>
                            <Button
                                size="lg"
                                bg={buttonBg}
                                color="white"
                                _hover={{ bg: buttonHoverBg }}
                                onClick={() => navigate('/signup')}
                                px={8}
                            >
                                Get Started
                            </Button>
                            <Button
                                size="lg"
                                variant="outline"
                                onClick={() => navigate('/login')}
                            >
                                Sign In
                            </Button>
                        </HStack>
                    </Box>
                    <Box
                        flex={1}
                        position="relative"
                        rounded="2xl"
                        overflow="hidden"
                        shadow="2xl"
                    >
                        <Image
                            src="/hero-image.jpg"
                            alt="Dating App"
                            w="full"
                            h={{ base: "300px", md: "500px" }}
                            objectFit="cover"
                            fallbackSrc="https://images.unsplash.com/photo-1516500896640-0ae9c5d94042?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80"
                        />
                    </Box>
                </Flex>

                {/* Features Section */}
                <Box mt={32}>
                    <Heading
                        textAlign="center"
                        mb={16}
                        color={headingColor}
                        size="xl"
                    >
                        Why Choose Our Platform
                    </Heading>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
                        <Feature
                            icon={FaHeart}
                            title="Smart Matching"
                            description="Our AI-powered algorithm finds compatible matches based on your preferences and personality."
                        />
                        <Feature
                            icon={FaSearch}
                            title="Advanced Search"
                            description="Easily find potential matches using our detailed search filters and preferences."
                        />
                        <Feature
                            icon={FaComments}
                            title="Real Connections"
                            description="Engage in meaningful conversations with our secure and intuitive chat system."
                        />
                        <Feature
                            icon={FaUserFriends}
                            title="Verified Profiles"
                            description="Feel secure with our verified user profiles and safety measures."
                        />
                    </SimpleGrid>
                </Box>
            </Container>
        </Box>
    );
};

export default Landing;