import api from './api';

export interface UserProfile {
    id: string;
    email: string;
    name: string;
    onboardingCompleted: boolean;
    gender?: string;
    birthDate?: string;
    bio?: string;
    photos?: string[];
    interests?: string[];
    location?: {
        type: string;
        coordinates: [number, number];
    };
    createdAt?: string;
    updatedAt?: string;
}

export class UserServiceError extends Error {
    constructor(
        message: string,
        public status?: number,
        public data?: any
    ) {
        super(message);
        this.name = 'UserServiceError';
    }
}

async function handleRequest<T>(request: Promise<T>): Promise<T> {
    try {
        return await request;
    } catch (error) {
        if (error instanceof ApiError) {
            throw new UserServiceError(error.message, error.status, error.data);
        }
        throw new UserServiceError('An unexpected error occurred');
    }
}

async function uploadPhotos(photos: (string | File)[]): Promise<string[]> {
    const uploadedPhotos: string[] = [];
    
    for (const photo of photos) {
        if (photo instanceof File) {
            const formData = new FormData();
            formData.append('photo', photo);
            
            const response = await api.post('/api/users/upload-photo', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            
            uploadedPhotos.push(response.data.url);
        } else {
            uploadedPhotos.push(photo);
        }
    }
    
    return uploadedPhotos;
}

export const userService = {
    getCurrentUser: async (): Promise<UserProfile> => {
        console.log('Fetching current user...');
        const response = await api.get('/auth/me');
        console.log('Current user response:', response.data);
        return response.data;
    },

    updateProfile: async (data: Partial<UserProfile>): Promise<UserProfile> => {
        console.log('Updating profile with data:', data);
        const response = await api.post('/api/onboarding/complete', data);
        console.log('Profile update response:', response.data);
        return response.data.user;
    }
};