import React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { LanguageProvider } from './contexts/LanguageContext';
import { AuthProvider } from './contexts/AuthContext';
import AppRoutes from './routes';

function App(): JSX.Element {
    return (
        <BrowserRouter>
            <ChakraProvider theme={theme}>
                <LanguageProvider>
                    <AuthProvider>
                        <AppRoutes />
                    </AuthProvider>
                </LanguageProvider>
            </ChakraProvider>
        </BrowserRouter>
    );
}

export default App;
