import React, { createContext, useContext, useState, useCallback, useMemo, type ReactNode } from 'react';

type Language = 'English' | 'Français';

interface Translations {
    [key: string]: {
        [key in Language]: string;
    };
}

interface LanguageContextType {
    t: (key: string) => string;
    selectedLanguage: Language;
    toggleLanguage: () => void;
}

const translations: Translations = {
    // Auth
    login: {
        English: 'Login',
        Français: 'Connexion',
    },
    logout: {
        English: 'Logout',
        Français: 'Déconnexion',
    },
    register: {
        English: 'Register',
        Français: 'Inscription',
    },

    // Profile
    profile: {
        English: 'Profile',
        Français: 'Profil',
    },
    bio: {
        English: 'Bio',
        Français: 'Bio',
    },
    interests: {
        English: 'Interests',
        Français: 'Centres d\'intérêt',
    },
    preferences: {
        English: 'Preferences',
        Français: 'Préférences',
    },
    lifestyle: {
        English: 'Lifestyle',
        Français: 'Mode de vie',
    },
    ageRange: {
        English: 'Age Range',
        Français: 'Tranche d\'âge',
    },
    distance: {
        English: 'Distance',
        Français: 'Distance',
    },
    drinking: {
        English: 'Drinking',
        Français: 'Alcool',
    },
    smoking: {
        English: 'Smoking',
        Français: 'Tabac',
    },
    exercise: {
        English: 'Exercise',
        Français: 'Exercice',
    },
    diet: {
        English: 'Diet',
        Français: 'Régime',
    },

    // Actions
    save: {
        English: 'Save',
        Français: 'Enregistrer',
    },
    cancel: {
        English: 'Cancel',
        Français: 'Annuler',
    },
    edit: {
        English: 'Edit',
        Français: 'Modifier',
    },
    delete: {
        English: 'Delete',
        Français: 'Supprimer',
    },

    // Settings
    settings: {
        English: 'Settings',
        Français: 'Paramètres',
    },
    privacySettings: {
        English: 'Privacy Settings',
        Français: 'Paramètres de confidentialité',
    },
    safetyCenter: {
        English: 'Safety Center',
        Français: 'Centre de sécurité',
    },
    notifications: {
        English: 'Notifications',
        Français: 'Notifications',
    },
    enableNotifications: {
        English: 'Enable Notifications',
        Français: 'Activer les notifications',
    },
    disableNotifications: {
        English: 'Disable Notifications',
        Français: 'Désactiver les notifications',
    },
    toggleDarkMode: {
        English: 'Toggle Dark Mode',
        Français: 'Changer le mode sombre',
    },

    // Status and placeholders
    loading: {
        English: 'Loading...',
        Français: 'Chargement...',
    },
    saving: {
        English: 'Saving...',
        Français: 'Enregistrement...',
    },
    notSpecified: {
        English: 'Not specified',
        Français: 'Non spécifié',
    },
    locationNotSet: {
        English: 'Location not set',
        Français: 'Lieu non défini',
    },
    noBio: {
        English: 'No bio yet',
        Français: 'Pas encore de bio',
    },
    enterBio: {
        English: 'Enter your bio...',
        Français: 'Entrez votre bio...',
    },

    // New translations
    welcomeBack: {
        English: 'Welcome Back',
        Français: 'Bienvenue',
    },
    loginToContinue: {
        English: 'Login to continue',
        Français: 'Connectez-vous pour continuer',
    },
    email: {
        English: 'Email',
        Français: 'Courriel',
    },
    enterEmail: {
        English: 'Enter your email',
        Français: 'Entrez votre courriel',
    },
    password: {
        English: 'Password',
        Français: 'Mot de passe',
    },
    enterPassword: {
        English: 'Enter your password',
        Français: 'Entrez votre mot de passe',
    },
    dontHaveAccount: {
        English: "Don't have an account?",
        Français: 'Vous n\'avez pas de compte?',
    },
    signUp: {
        English: 'Sign Up',
        Français: 'Inscrivez-vous',
    },
    onboarding: {
        English: 'Onboarding',
        Français: 'Premiers pas',
    },
    error: {
        English: 'Error',
        Français: 'Erreur',
    },
    success: {
        English: 'Success',
        Français: 'Succès',
    },
    loginSuccess: {
        English: 'Login successful',
        Français: 'Connexion réussie',
    },
    loginError: {
        English: 'Login failed',
        Français: 'Échec de la connexion',
    },
    name: {
        English: 'Name',
        Français: 'Nom',
    },
    photos: {
        English: 'Photos',
        Français: 'Photos',
    },
    next: {
        English: 'Next',
        Français: 'Suivant',
    },
    back: {
        English: 'Back',
        Français: 'Précédent',
    },
    finish: {
        English: 'Finish',
        Français: 'Terminer',
    },
    skip: {
        English: 'Skip',
        Français: 'Passer',
    },
    required: {
        English: 'Required',
        Français: 'Requis',
    },
    optional: {
        English: 'Optional',
        Français: 'Facultatif',
    },
    invalidEmail: {
        English: 'Invalid email address',
        Français: 'Adresse courriel invalide',
    },
    passwordRequired: {
        English: 'Password is required',
        Français: 'Le mot de passe est requis',
    },
    passwordTooShort: {
        English: 'Password must be at least 6 characters',
        Français: 'Le mot de passe doit contenir au moins 6 caractères',
    },
    passwordsDontMatch: {
        English: 'Passwords do not match',
        Français: 'Les mots de passe ne correspondent pas',
    },
    emailRequired: {
        English: 'Email is required',
        Français: 'Le courriel est requis',
    },
    nameRequired: {
        English: 'Name is required',
        Français: 'Le nom est requis',
    },
    bioRequired: {
        English: 'Bio is required',
        Français: 'La bio est requise',
    },
    interestsRequired: {
        English: 'Please select at least one interest',
        Français: 'Veuillez sélectionner au moins un centre d\'intérêt',
    },
    photosRequired: {
        English: 'Please upload at least one photo',
        Français: 'Veuillez télécharger au moins une photo',
    },
    uploadPhoto: {
        English: 'Upload Photo',
        Français: 'Télécharger une photo',
    },
    removePhoto: {
        English: 'Remove Photo',
        Français: 'Supprimer la photo',
    },
    addInterest: {
        English: 'Add Interest',
        Français: 'Ajouter un centre d\'intérêt',
    },
    removeInterest: {
        English: 'Remove Interest',
        Français: 'Supprimer un centre d\'intérêt',
    },
    savingChanges: {
        English: 'Saving changes...',
        Français: 'Enregistrement des modifications...',
    },
    changesSaved: {
        English: 'Changes saved successfully',
        Français: 'Les modifications ont été enregistrées avec succès',
    },
    errorSavingChanges: {
        English: 'Error saving changes',
        Français: 'Erreur lors de l\'enregistrement des modifications',
    },
    confirmDelete: {
        English: 'Are you sure you want to delete this?',
        Français: 'Êtes-vous sûr de vouloir supprimer ceci?',
    },
    yes: {
        English: 'Yes',
        Français: 'Oui',
    },
    no: {
        English: 'No',
        Français: 'Non',
    },
    privacyPolicy: {
        English: 'Privacy Policy',
        Français: 'Politique de confidentialité',
    },
    termsOfService: {
        English: 'Terms of Service',
        Français: 'Conditions d\'utilisation',
    },
    and: {
        English: 'and',
        Français: 'et',
    },
    bySigningUp: {
        English: 'By signing up, you agree to our',
        Français: 'En vous inscrivant, vous acceptez nos',
    },
};

const LanguageContext = createContext<LanguageContextType | null>(null);

export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};

export const useTranslation = useLanguage; // Alias for backward compatibility

interface LanguageProviderProps {
    children: ReactNode;
}

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
    const [selectedLanguage, setSelectedLanguage] = useState<Language>('English');

    const toggleLanguage = useCallback(() => {
        setSelectedLanguage(prev => prev === 'English' ? 'Français' : 'English');
    }, []);

    const t = useCallback((key: string): string => {
        if (!translations[key]) {
            console.warn(`Translation missing for key: ${key}`);
            return key;
        }
        return translations[key][selectedLanguage];
    }, [selectedLanguage]);

    const value = useMemo(() => ({
        t,
        selectedLanguage,
        toggleLanguage,
    }), [t, selectedLanguage, toggleLanguage]);

    return (
        <LanguageContext.Provider value={value}>
            {children}
        </LanguageContext.Provider>
    );
};