import React, { useState, ChangeEvent, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    Heading,
    Input,
    VStack,
    Text,
    useColorModeValue,
    Progress,
    Select,
    Textarea,
    SimpleGrid,
    Tag,
    TagLabel,
    TagCloseButton,
    HStack,
    Image,
    IconButton,
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
    Checkbox,
    CheckboxGroup,
    useToast,
} from '@chakra-ui/react';
import { FaCamera, FaUpload } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import { UserServiceError, UserProfile } from '../services/userService';

interface OnboardingData {
    photos: (string | File)[];
    interests: string[];
    hobbies: string[];
    interestedIn: ('male' | 'female' | 'other')[];
    ageRange: [number, number];
    bio: string;
    personality: string[];
    lookingFor: string[];
    lifestyle: {
        drinking: string;
        smoking: string;
        exercise: string;
        diet: string;
    };
}

const INTERESTS_OPTIONS = [
    'Music', 'Movies', 'Travel', 'Food', 'Art', 'Sports', 'Reading', 'Gaming',
    'Photography', 'Dancing', 'Cooking', 'Fitness', 'Technology', 'Nature',
    'Fashion', 'Animals', 'Writing', 'Yoga', 'Meditation', 'Shopping'
];

const PERSONALITY_TRAITS = [
    'Outgoing', 'Introverted', 'Creative', 'Analytical', 'Adventurous',
    'Calm', 'Energetic', 'Organized', 'Spontaneous', 'Ambitious'
];

const LOOKING_FOR = [
    'Long-term relationship', 'Casual dating', 'Marriage', 'Friendship',
    'Activity partner'
];

const LIFESTYLE_OPTIONS = {
    drinking: ['Never', 'Rarely', 'Socially', 'Regularly'],
    smoking: ['Never', 'Socially', 'Regularly', 'Trying to quit'],
    exercise: ['Never', 'Sometimes', 'Regularly', 'Daily'],
    diet: ['Everything', 'Vegetarian', 'Vegan', 'Kosher', 'Halal', 'Other']
};

const Onboarding: React.FC = () => {
    const navigate = useNavigate();
    const { updateProfile } = useAuth();
    const toast = useToast();
    const [step, setStep] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [onboardingData, setOnboardingData] = useState<OnboardingData>({
        photos: [],
        interests: [],
        hobbies: [],
        interestedIn: [],
        ageRange: [18, 50],
        bio: '',
        personality: [],
        lookingFor: [],
        lifestyle: {
            drinking: '',
            smoking: '',
            exercise: '',
            diet: ''
        }
    });

    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.600', 'gray.400');

    const totalSteps = 6;
    const progress = (step / totalSteps) * 100;

    const handlePhotoUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const newPhotos = [...onboardingData.photos];
            for (let i = 0; i < files.length; i++) {
                if (newPhotos.length < 6) { // Maximum 6 photos
                    newPhotos.push(files[i]);
                }
            }
            setOnboardingData({ ...onboardingData, photos: newPhotos });
        }
    };

    const handlePhotoPreview = (file: File | string): string => {
        if (file instanceof File) {
            return URL.createObjectURL(file);
        }
        return file;
    };

    const handleInterestToggle = (interest: string) => {
        const newInterests = onboardingData.interests.includes(interest)
            ? onboardingData.interests.filter(i => i !== interest)
            : [...onboardingData.interests, interest];
        setOnboardingData({ ...onboardingData, interests: newInterests });
    };

    const handleHobbyInput = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const input = e.currentTarget;
            const hobby = input.value.trim();
            if (hobby && !onboardingData.hobbies.includes(hobby)) {
                setOnboardingData({
                    ...onboardingData,
                    hobbies: [...onboardingData.hobbies, hobby]
                });
                input.value = '';
            }
        }
    };

    const handleCheckboxGroupChange = (field: keyof OnboardingData, values: any) => {
        setOnboardingData({
            ...onboardingData,
            [field]: values
        });
    };

    const handleRangeChange = (value: [number, number]) => {
        setOnboardingData({
            ...onboardingData,
            ageRange: value
        });
    };

    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setOnboardingData({
            ...onboardingData,
            bio: e.target.value
        });
    };

    const handlePersonalityChange = (trait: string, checked: boolean) => {
        const newTraits = checked
            ? [...onboardingData.personality, trait]
            : onboardingData.personality.filter(t => t !== trait);
        setOnboardingData({
            ...onboardingData,
            personality: newTraits
        });
    };

    const handleNext = async () => {
        if (step === totalSteps) {
            try {
                setIsSubmitting(true);

                // Validate required fields
                if (onboardingData.photos.length < 2) {
                    toast({
                        title: 'Photos Required',
                        description: 'Please add at least 2 photos',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                    setIsSubmitting(false);
                    return;
                }
                if (onboardingData.interests.length === 0) {
                    toast({
                        title: 'Interests Required',
                        description: 'Please select at least one interest',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                    setIsSubmitting(false);
                    return;
                }
                if (onboardingData.interestedIn.length === 0) {
                    toast({
                        title: 'Preference Required',
                        description: 'Please select who you are interested in',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                    setIsSubmitting(false);
                    return;
                }
                if (!onboardingData.bio) {
                    toast({
                        title: 'Bio Required',
                        description: 'Please write a short bio',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                    setIsSubmitting(false);
                    return;
                }

                // Update profile with onboarding data
                await updateProfile({
                    photos: onboardingData.photos,
                    interests: [...onboardingData.interests, ...onboardingData.hobbies],
                    bio: onboardingData.bio,
                    preferences: {
                        ageRange: {
                            min: onboardingData.ageRange[0],
                            max: onboardingData.ageRange[1]
                        },
                        gender: onboardingData.interestedIn
                    },
                    lifestyle: onboardingData.lifestyle,
                    onboardingCompleted: true
                });

                toast({
                    title: 'Profile Updated',
                    description: 'Your profile has been successfully created!',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });

                // Navigation is now handled by updateProfile
            } catch (error) {
                console.error('Onboarding error:', error);
                toast({
                    title: 'Error',
                    description: error instanceof UserServiceError ? error.message : 'Failed to update profile',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setIsSubmitting(false);
            }
        } else {
            setStep(step + 1);
        }
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <VStack spacing={6} align="stretch">
                        <Heading size="lg">Add Your Photos</Heading>
                        <Text color={textColor}>Add at least 2 photos of yourself</Text>
                        <SimpleGrid columns={3} spacing={4}>
                            {onboardingData.photos.map((photo, index) => (
                                <Box key={index} position="relative">
                                    <Image
                                        src={handlePhotoPreview(photo)}
                                        alt={`Photo ${index + 1}`}
                                        borderRadius="md"
                                        objectFit="cover"
                                        w="100%"
                                        h="150px"
                                    />
                                </Box>
                            ))}
                            {onboardingData.photos.length < 6 && (
                                <Box
                                    as="label"
                                    htmlFor="photo-upload"
                                    cursor="pointer"
                                    borderWidth={2}
                                    borderStyle="dashed"
                                    borderRadius="md"
                                    p={4}
                                    textAlign="center"
                                    h="150px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <VStack spacing={2}>
                                        <FaCamera size={24} />
                                        <Text>Add Photo</Text>
                                    </VStack>
                                    <Input
                                        id="photo-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={handlePhotoUpload}
                                        display="none"
                                    />
                                </Box>
                            )}
                        </SimpleGrid>
                    </VStack>
                );

            case 2:
                return (
                    <VStack spacing={6} align="stretch">
                        <Heading size="lg">Your Interests</Heading>
                        <Text color={textColor}>Select your interests</Text>
                        <SimpleGrid columns={3} spacing={4}>
                            {INTERESTS_OPTIONS.map((interest) => (
                                <Tag
                                    key={interest}
                                    size="lg"
                                    variant={onboardingData.interests.includes(interest) ? 'solid' : 'outline'}
                                    colorScheme="blue"
                                    cursor="pointer"
                                    onClick={() => handleInterestToggle(interest)}
                                >
                                    <TagLabel>{interest}</TagLabel>
                                </Tag>
                            ))}
                        </SimpleGrid>
                    </VStack>
                );

            case 3:
                return (
                    <VStack spacing={6} align="stretch">
                        <Heading size="lg">About You</Heading>
                        <FormControl>
                            <FormLabel>Bio</FormLabel>
                            <Textarea
                                value={onboardingData.bio}
                                onChange={handleTextAreaChange}
                                placeholder="Write a short bio about yourself..."
                                rows={6}
                            />
                        </FormControl>
                    </VStack>
                );

            case 4:
                return (
                    <VStack spacing={6} align="stretch">
                        <Heading size="lg">Preferences</Heading>
                        <FormControl>
                            <FormLabel>Interested In</FormLabel>
                            <CheckboxGroup
                                value={onboardingData.interestedIn}
                                onChange={(values) => handleCheckboxGroupChange('interestedIn', values)}
                            >
                                <VStack align="start">
                                    <Checkbox value="male">Men</Checkbox>
                                    <Checkbox value="female">Women</Checkbox>
                                    <Checkbox value="other">Other</Checkbox>
                                </VStack>
                            </CheckboxGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Age Range</FormLabel>
                            <RangeSlider
                                value={onboardingData.ageRange}
                                min={18}
                                max={100}
                                step={1}
                                onChange={handleRangeChange}
                            >
                                <RangeSliderTrack>
                                    <RangeSliderFilledTrack />
                                </RangeSliderTrack>
                                <RangeSliderThumb index={0} />
                                <RangeSliderThumb index={1} />
                            </RangeSlider>
                            <Text mt={2}>
                                {onboardingData.ageRange[0]} - {onboardingData.ageRange[1]} years
                            </Text>
                        </FormControl>
                    </VStack>
                );

            case 5:
                return (
                    <VStack spacing={6} align="stretch">
                        <Heading size="lg">Lifestyle</Heading>
                        <FormControl>
                            <FormLabel>Drinking</FormLabel>
                            <Select
                                value={onboardingData.lifestyle.drinking}
                                onChange={(e) => setOnboardingData({
                                    ...onboardingData,
                                    lifestyle: { ...onboardingData.lifestyle, drinking: e.target.value }
                                })}
                            >
                                <option value="">Select option</option>
                                {LIFESTYLE_OPTIONS.drinking.map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Smoking</FormLabel>
                            <Select
                                value={onboardingData.lifestyle.smoking}
                                onChange={(e) => setOnboardingData({
                                    ...onboardingData,
                                    lifestyle: { ...onboardingData.lifestyle, smoking: e.target.value }
                                })}
                            >
                                <option value="">Select option</option>
                                {LIFESTYLE_OPTIONS.smoking.map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Exercise</FormLabel>
                            <Select
                                value={onboardingData.lifestyle.exercise}
                                onChange={(e) => setOnboardingData({
                                    ...onboardingData,
                                    lifestyle: { ...onboardingData.lifestyle, exercise: e.target.value }
                                })}
                            >
                                <option value="">Select option</option>
                                {LIFESTYLE_OPTIONS.exercise.map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Diet</FormLabel>
                            <Select
                                value={onboardingData.lifestyle.diet}
                                onChange={(e) => setOnboardingData({
                                    ...onboardingData,
                                    lifestyle: { ...onboardingData.lifestyle, diet: e.target.value }
                                })}
                            >
                                <option value="">Select option</option>
                                {LIFESTYLE_OPTIONS.diet.map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </VStack>
                );

            case 6:
                return (
                    <VStack spacing={6} align="stretch">
                        <Heading size="lg">Looking For</Heading>
                        <Text color={textColor}>What kind of relationship are you looking for?</Text>
                        <CheckboxGroup
                            value={onboardingData.lookingFor}
                            onChange={(values) => handleCheckboxGroupChange('lookingFor', values)}
                        >
                            <VStack align="start" spacing={4}>
                                {LOOKING_FOR.map(option => (
                                    <Checkbox key={option} value={option}>
                                        {option}
                                    </Checkbox>
                                ))}
                            </VStack>
                        </CheckboxGroup>
                    </VStack>
                );

            default:
                return null;
        }
    };

    return (
        <Container maxW="container.md" py={8}>
            <Progress value={progress} size="sm" mb={8} borderRadius="full" />
            <Box bg={bgColor} p={8} borderRadius="lg" boxShadow="xl">
                {renderStep()}
                <HStack justify="flex-end" mt={8}>
                    {step > 1 && (
                        <Button onClick={() => setStep(step - 1)} variant="outline">
                            Previous
                        </Button>
                    )}
                    <Button
                        onClick={handleNext}
                        colorScheme="blue"
                        isLoading={isSubmitting}
                    >
                        {step === totalSteps ? 'Finish' : 'Next'}
                    </Button>
                </HStack>
            </Box>
        </Container>
    );
};

export default Onboarding;
